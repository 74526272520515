document.addEventListener("DOMContentLoaded", function () {
  // constでもいけそうな気がするけどletで宣言
  let pollingTimer;
  let pollingTimeout;

  checkInitialJobStatus();

  function checkInitialJobStatus() {
    fetch("/sites/job_status")
      .then((response) => response.json())
      .then((data) => {
        // ステータスが空じゃない場合ポーリングを開始
        if (data.status != null) {
          startPolling();
        }
      })
      .catch((error) =>
        console.error("Error checking initial job status:", error)
      );
  }

  function startPolling() {
    const timeout = 120000; // 2分 とりあえずの設定（30分くらいがいい？）
    const interval = 5000; // 5秒周期
    pollingTimer = setInterval(checkJobStatus, interval);
    pollingTimeout = setTimeout(function () {
      clearInterval(pollingTimer);
      console.log("update infomation polling timed out.");
    }, timeout);
  }
  function checkJobStatus() {
    fetch("/sites/job_status")
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "completed") {
          clearInterval(pollingTimer);
          clearTimeout(pollingTimeout);
          alert("Update completed successfully!");
          clearJobStatus();
          console.log("update infomation終了");
          window.location.reload(); // ダイヤログを閉じたら画面リロード
        }
      })
      .catch((error) => console.error("Error checking job status:", error));
  }
  function clearJobStatus() {
    console.log("clearJobStatus呼び出し");
    fetch("/sites/clear_job_status", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
    }).catch((error) => console.error("Error clearing job status:", error));
  }
});
